﻿/* _stacked-tables.scss -- */
@import "../base/colors";
@import "../base/typography";
@import "../helpers/mixins";

.species-list {
    div {
        padding: 2px 0 2px 8px;
        font-size: .9rem;

        .blue {
            color: $cafm-dark-blue;
        }
    }

    @media#{$above992} {
        div:nth-of-type(4n),
        div:nth-of-type(4n-1),
        div:nth-of-type(4n-2),
        div:nth-of-type(4n-3) {
            background-color: $cafm-ultralight-beige;
        }

        div:nth-of-type(8n),
        div:nth-of-type(8n-1),
        div:nth-of-type(8n-2),
        div:nth-of-type(8n-3) {
            background-color: $cafm-light-blue;
        }
    }

    @media#{$between768and992} {
        div:nth-of-type(3n),
        div:nth-of-type(3n-1),
        div:nth-of-type(3n-2) {
            background-color: $cafm-ultralight-beige;
        }

        div:nth-of-type(6n),
        div:nth-of-type(6n-1),
        div:nth-of-type(6n-2) {
            background-color: $cafm-light-blue;
        }
    }

    @media#{$below768} {
        div:nth-of-type(2n),
        div:nth-of-type(2n-1), {
            background-color: $cafm-ultralight-beige;
        }

        div:nth-of-type(4n),
        div:nth-of-type(4n-1) {
            background-color: $cafm-light-blue;
        }
    }
}

.fwc-table, .report-table {
    table {
        width: 100%;
        border-collapse: collapse;
        /*@include link-icons;*/

        th {
            background-color: $cafm-dark-blue;
            border-right: 1px solid $cafm-dark-blue-tint;
            color: white;
            text-align: center;
            padding: 10px 8px;
            font-weight: 700;
/**/
            &:last-of-type {
                border-right: 0;
            }
        }

        tr {
            background-color: $cafm-white;

            td:last-of-type {
                border-right: 0;
            }

            /*td:first-of-type {
                border-left: 1px solid $cafm-dark-blue-tint;
            }*/
        }

        tr:nth-of-type(odd) {
            background-color: $cafm-ultralight-beige;
        }

        td {
            border-bottom: 1px solid $cafm-dark-blue-tint;
            border-right: 1px solid $cafm-dark-blue-tint;
            padding: 10px 8px;
            text-align: left;
            @include word-break;
        }

        p {
            margin: 0;
        }

        &.table-sm {
            td {
                padding: .3rem;
            }
        }
    }
}


/*RESPONSIVE TABLES*/
@media only screen and (max-width: 768px), (min-device-width: 768px) and (max-device-width: 1024px) {
    .fwc-table {
        table {
            /* Force table to not be like tables anymore */
            table, thead, tbody, th, td, tr {
                display: block;
            }
            /* Hide table headers (but not display: none;, for accessibility) */
            thead tr {
                position: absolute;
                top: -9999px;
                left: -9999px;
            }

            tr {
                border: 1px solid $cafm-mid-beige;
            }

            td {
                /* Behave  like a "row" */
                position: relative;
                /*padding-left: 50%;*/
                border-right: 0;
                font-size: 19px;
                box-sizing: initial;
                min-height: 49px;
            }

            td:before {
                /* Now like a table header */
                position: relative;
                /* Top/left values mimic padding */
                width: 100%;
                padding-right: 10px;
                content: attr(data-title);
                font-size: 17px;
                font-weight: 700;
                display: block;
                margin-bottom: 5px;
            }
        }
    }
}
