﻿// _mobile-menu.scss
@import "../base/colors";
@import "../base/typography";
@import "../helpers/mixins";

#fwc-mobile-menu {
    background-color: $cafm-dark-blue;
    font-size: 1.3rem;

    .mm-panels {
        &:before {
            position: absolute;
            display: block;
            content: "";
            width: 4px;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0,0,0,.3);
            z-index: 1;
        }
    }

    .mm-panel {
        .mm-navbar {
            border-color: $cafm-dark-blue-tint;

            a {
                color: white;
            }

            .mm-btn_prev {
                &:before {
                    border-color: white;
                    width: 15px;
                    height: 15px;
                }
            }
        }

        li.mm-listitem {
            &:after {
                left: 0;
                border-color: $cafm-dark-blue-tint;
            }

            a, span {
                padding: 20px 10px 20px 20px;
                font-size: 1.4rem;
                color: $cafm-white;
            }

            .mm-btn_next {
                &:before {
                    border-color: $cafm-dark-blue-tint;
                }

                &:after {
                    border-color: white;
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }

    a.menu-close {
        position: absolute;
        left: 20px;
        top: 0;
        opacity: 0;

        &:focus {
            opacity: 1;
        }
    }

    li.mobile-menu-name {
        background-color: $cafm-dark-blue-tint;
        font-weight: bold;
        text-align: center;
    }
}