﻿//theme

// reset
@import"../base/reset";

// libraries
@import "bootstrap";
@import"../vendor/jquery.mmenu.all";
@import "../vendor/animate";
@import "../vendor/hamburgers";
@import "../vendor/lightbox";

// sass utilities
@import "../helpers/mixins";
@import "../helpers/mediaqueries";

// base stuff
@import "../base/typography";
@import "../base/colors";

// layout related stylesheets
@import "../layout/desktop-navigation";
@import "../layout/mobile-navigation";
@import "../layout/header";
//@import "../layout/page-title";
@import "../layout/forms";
@import "../layout/footer";

// components and modules
@import "../components/validation";
@import "../components/buttons";
@import "../components/datepicker";
@import "../components/tables";
@import "../components/tabs";
@import "../components/accordion";
@import "../components/blockquote";
@import "../components/video";
@import "../components/alert-banner";
@import "../components/cards";


// styles

body {
    font-family: $base-font-family;
    color: $cafm-black;
    font-weight: $base-font-weight;
    font-size: $base-font-size;
    line-height: $base-line-height;
    background-color: $cafm-white;

    @media #{$below768} {
        font-size: 15px;
        line-height: 1.2;
    }
}
/*.body:not(.fwc-footer):not(.partners) {
    @include link-icons;
}*/
.content {
    @include link-icons;
}

.vertical-margin {
    margin-top: 30px;
    margin-bottom: 30px;
}

.margin-top {
    margin-top: 30px;
}

.margin-bottom {
    margin-bottom: 30px;
}

.wrapper {
    max-width: 2500px;
    margin: auto;
    overflow: hidden;

    @media #{$above1920} {
        position: relative;

        &:before, &:after {
            position: absolute;
            content: "";
            width: 60px;
            height: 100%;
            top: 0;
            background-repeat: repeat-y;
            background-size: 100%;
            z-index: 50;
        }

        &:before {
            background-image: url();
            background-position: top left;
            left: 0;
        }

        &:after {
            background-image: url();
            background-position: top right;
            right: 0;
        }
    }
}

img {
    max-width: 100%;

    .shadow {
        @include standard-box-shadow();
    }
}

a {
    color: $cafm-dark-blue;
    font-weight: 500;
    @include transition;

    &:hover {
        text-decoration: underline;
    }
}

.clickable {
    color: $cafm-dark-blue;
    cursor: pointer;
}

a.read-more {
    font-weight: 700;

    .svg-inline--fa {
        margin: 3px 0 0 5px;
        @include transition;
    }

    &:hover, &:focus {
        .svg-inline--fa {
            margin: 3px 0 0 10px;
        }
    }
}

a, input, button, select, textarea {
    &:focus {
        outline: none;
        box-shadow: 0px 0px 3px 3px #4C9AFF !important;
    }
}

.caption {
    @include caption;
}

.clear {
    clear: both;
}

.container {
    position: relative;

    &.static {
        position: static;
    }

    @media #{$above1200} {
        max-width: 1170px;
    }

    @media #{$below992} {
        width: 100%;
        max-width: 100%;
    }

    .container {
        padding-right: 0;
        padding-left: 0;
    }
}

a.backToTop {
    color: white;
    border-radius: 100px;
    background-color: $cafm-blue;
    height: 50px;
    width: 50px;
    position: fixed;
    right: 30px;
    bottom: 30px;
    text-decoration: none;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    @include transition(.5s);

    &.btt-in {
        visibility: visible;
        opacity: .5;
    }

    .svg-inline--fa {
        display: block;
        text-align: center;
        font-size: 40px;
        width: 100%;
        margin-top: -1px;
        //@include vertical-align;
    }

    @media #{$below768} {
        display: none;
    }
}

.loading-events {
    text-align: center;
    color: $cafm-dark-brown;
}

ul.pagination {
    margin: 15px 0;
    padding: 0;

    li.page-item {
        list-style-type: none;
        padding: 0;

        a.page-link {
            color: $cafm-blue;
            min-height: 45px;
            min-width: 35px;
        }

        &.active {
            a.page-link {
                color: white;
                background-color: $cafm-blue;
                border-color: $cafm-blue;
            }
        }
    }
}

[v-cloak] {
    display: none;
}

.site-body figure {
    position: relative;
    display: table;

    figcaption img {
        display: block;
        width: 100%;
    }

    figcaption {
        @include caption();
        position: relative;
        display: table-caption;
        caption-side: bottom;
    }
}

@-moz-document url-prefix() {
    figure figcaption {
        display: block !important;
    }
}

.bg-beige {
    background-color: $cafm-light-beige;
    color: $cafm-dark-brown;

    h1, h2, h3, h4, h5, h6, h7 {
        color: $cafm-dark-brown;
    }

    a {
        color: $cafm-dark-blue;
    }
}

.bg-dark-brown {
    background-color: $cafm-dark-brown;
    color: $cafm-white;

    h1, h2, h3, h4, h5, h6, h7 {
        color: $cafm-white;
    }

    a {
        color: $cafm-light-brown;
    }
}

.bg-dark-blue {
    background-color: $cafm-dark-blue;
    color: $cafm-white;

    h1, h2, h3, h4, h5, h6, h7 {
        color: $cafm-white;
    }

    a {
        color: $cafm-light-blue;
    }
}

.bg-light-blue {
    background-color: $cafm-light-blue;
    color: $cafm-dark-blue;

    h1, h2, h3, h4, h5, h6, h7 {
        color: $cafm-dark-blue;
    }

    a {
        color: $cafm-dark-blue;
    }
}

@media #{$below768} {
    label {
        margin-bottom: .3rem;
    }
}

a[data-toggle=collapse] {

    .cafm-minus {
        display: inline-block;
    }

    .cafm-plus {
        display: none;
    }

    &.collapsed {
        .cafm-minus {
            display: none;
        }

        .cafm-plus {
            display: inline-block;
        }

        .section-title {
            border-bottom: none;
            margin-bottom: 0px;
        }
    }
}

.catch-review-table {
    img {
        max-width: 100px;
        max-height: 100px;
        display: inline;
        margin-right: 1rem;

        @media #{$between768and1200} {
            max-width: 80px;
            max-height: 80px;
        }

        @media #{$below768} {
            max-width: 50px;
            max-height: 50px;
        }
    }
}

.fb-page, .fb-page iframe[style], .fb-page span {
    width: 100% !important;
}

.records-modal .fwc-table {
    max-height: calc(100vh - 225px);
    overflow: scroll;
}

/*Added form Jquery datatables*/
/*06/11/2022*/

.dataTables_length {
    width: 50%;
    float: left;
}

.dataTables_filter {
    width: 50%;
    float: left;
}

.dataTables_filter label {
    width: 100%;
}

.dataTables_filter input[type="search"] {
    width: 87%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid;
    border-color: #7396a4;
}


.dataTables_info {
    text-align: center;
}

.dataTables_paginate {
    text-align: center;
}

.paginate_button {
    padding: 5px 15px;
    background: #38697e;
    color: #fff;
    text-decoration: none;
    margin: 5px 2px;
}

.paginate_button:hover {
    text-decoration: none;
    color: white;
    background: #a7a7a7;
    cursor: pointer;
}
.paginate_button.current {
    text-decoration: none;
    color: white;
    background: #a7a7a7;
}
