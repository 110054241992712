﻿//desktop navigation

@import "../base/colors";
@import "../base/typography";
@import "../helpers/mixins";

#fwc-mobile-menu:not( .mm-menu ) {
    display: none;
}

@media #{$below992} {
    #desktop-navigation {
        display: none;
    }
}

.navbar {
    border-radius: 0;
    margin-bottom: 0;

    &#desktop-navigation {
        padding: 0;
        min-height: 40px;
        max-height: 50px;
        background-color: $cafm-dark-blue;
        color: $cafm-white;
        border-color: $cafm-dark-blue-tint;

        @media #{$between991and1200} {
            font-size:1.1rem;
        } 

        li > a {
            border-left: 1px solid $cafm-dark-blue-tint;
            padding: 10px 15px;
        }

        li:first-child > a {
            border-left: 1px solid $cafm-dark-blue-tint;
        }

        li:last-child > a {
            border-right: 1px solid $cafm-dark-blue-tint;
        }

        li.dropdown.show > a {
            background-color: $cafm-dark-blue-tint;
            color: $cafm-white;
        }

        li > a {
            color: $cafm-white;

            &:hover {
                background-color: $cafm-dark-blue-tint;
            }
        }

        a.dropdown-item {
            color: $cafm-dark-blue;

            &:hover {
                color: $cafm-white;
                background-color: $cafm-dark-blue;
            }
        }
    }

    &#admin-navigation {

        @media #{$above768} {
            min-height: 40px;
            max-height: 50px;
        }

        background-color: $cafm-dark-brown;
        color: $cafm-white;
        border-color: $cafm-light-brown;
        padding: 0;

        li > a {
            border-left: 1px solid $cafm-light-beige;
            padding: 10px 15px;
        }

        li:first-child > a {
            border-left: 1px solid $cafm-light-beige;
        }

        li:last-child > a {
            border-right: 1px solid $cafm-light-beige;
        }

        li.dropdown.show > a {
            background-color: $cafm-light-brown;
            color: $cafm-white;
        }

        li > a {
            color: $cafm-white;

            &:hover {
                background-color: $cafm-light-brown;
            }
        }

        a.navbar-brand {
            color: $cafm-light-beige;
            text-transform: uppercase;
        }

        a.dropdown-item {
            color: $cafm-dark-brown;

            &:hover {
                color: $cafm-white;
                background-color: $cafm-dark-brown;
            }
        }
    }

    ul {
        margin: 0;
    }

    ul li {
        list-style: none;
        list-style-type: none;
        padding: 0;
    }
}