﻿// _forms.scss
@import "../base/colors";
@import "../base/typography";
@import "../helpers/mixins";

.cafm-form {
    input[type=text], input[type=email], input[type=password], input[type=number], textarea {
        background-color: white;
        border-radius: 0;
        width: 100%;
        border: 0;
        padding: 8px 10px;
        font-size: 18px;
        color: #58595b;
        border: 1px solid $cafm-dark-blue;
        @include standard-box-shadow;

        @media #{$below768} {
            font-size:15px;
            padding: 7px;
        }
    }

    input[type=file] {
        display: none;
    }

    input:disabled:hover {
        cursor:not-allowed;
    }

    textarea {
        //min-height: 240px;
        border: 1px solid $cafm-dark-blue;
    }

    h4 {
        margin-bottom: 15px;
    }
}

#user-details-app {
    input[type=file] {
        display: block;
    }
}

.modal {
    background-color: rgba(0, 0, 0, 0.3);
}

.modal-backdrop {
    position: relative;
}

/*.custom-checkbox.top-margin {
    margin-top:20px;
}
.custom-control-label::before {
    color: $cafm-dark-blue;
    background-color: $cafm-white;
    border-radius: 0px;
    border: 2px solid $cafm-dark-blue;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $cafm-dark-blue;
}*/

.drop-label {
    position: relative;
    vertical-align: middle;
    display: block;

    .svg-inline--fa {
        font-size: 1.2em;
        right: 12px;
        top: 12px;
        position: absolute;
        pointer-events: none;
        display: block;
        color: $cafm-dark-blue;

        @media #{$below768} {
            right: 7px;
            top: 8px;
        }
    }

    select.drop-select {
        background-color: white;
        border: 1px solid $cafm-dark-blue;
        display: inline-block;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        vertical-align: middle;
        font-weight: 400;
        font-size: 18px;
        padding: 8px 0px 8px 10px;
        width: 100%;
        @include standard-box-shadow;

        &::-ms-expand {
            display: none;
        }

        @media #{$below768} {
            font-size: 15px;
            padding: 6px 0 6px 7px;
        }
    }

    &.disabled {
        opacity: .4;
    }
}

.autocomplete {
    position: relative;
    width: 130px;
}

.autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid $cafm-dark-blue;
    border-top: none;
    height: auto;
    /*max-height: 150px;*/
    width: 90%;
    min-width: 200px;
    overflow: auto;
    position: absolute;
    z-index: 1000;
    background-color: $cafm-white;
    @include standard-box-shadow;
}

.autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 3px 0px 0px 10px;
    cursor: pointer;

    &:hover, &.is-active {
        background-color: $cafm-dark-blue;
        color: $cafm-white;
    }
}