﻿// _buttons.scss
@import "../base/colors";
@import "../base/typography";
@import "../helpers/mixins";

@mixin fwc-button($color, $active-color) {
    background-color: $color;
    color: white;
    border: 0;
    text-shadow: 1px 1px 1px rgba(0,0,0,.2);
    @include transition;

    &:after, &:before {
        position: absolute;
        content: "";
        display: block;
        right: 4px;
        left: 4px;
        width: 100% -8px;
        height: 2px;
    }

    &:after {
        bottom: 3px;
        background-color: rgba(0,0,0,.2);
    }

    &:before {
        top: 3px;
        background-color: rgba(255,255,255,.2);
    }

    &:hover, &:focus, &.is-active {
        background-color: $active-color;
        text-decoration: none;
    }

    @extend .base-fwc-button;
}

@mixin fwc-clear-button($color) {
    background-color: transparent;
    color: $color;
    border: 2px solid $color;

    &:hover, &:focus, &.is-active {
        background-color: $color;
        text-decoration: none;
        color: $cafm-white;
    }

    @extend .base-fwc-button;
}

.base-fwc-button {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 1.2rem;
    text-align: center;
    text-transform: uppercase;
    display: inline-block;
    padding: 10px 35px;
    position: relative;
    max-width: 100%;
    box-sizing: border-box;

    @media #{$below768} {
        font-size: 1rem;
        padding: 8px 30px;
    }

    

    &:hover, &:focus, &:active {
        cursor: pointer;

        &:after, &:before {
            display: none;
        }
    }

    &[href$=".pdf"] {
        background-image: url('/Graphics/ui/pdf-white.png');
        background-position: 13px 50%;
        background-repeat: no-repeat;
    }

    &[href$=".png"], &[href$=".jpg"], &[href$=".svg"], &[href$=".jpeg"], &[href$=".gif"] {
        background-image: url('/Graphics/ui/pic-white.png');
        background-position: 13px 50%;
        background-repeat: no-repeat;
    }

    &[href$=".doc"], &[href$=".docx"], &[href$=".xls"], &[href$=".xlsx"], &[href$=".txt"] {
        background-image: url('/Graphics/ui/doc-white.png');
        background-position: 13px 50%;
        background-repeat: no-repeat;
    }

    &.external {
        background-image: url('/Graphics/ui/external-white.png');
        background-position: 13px 50%;
        background-repeat: no-repeat;
    }
}

.fwc-clear-button {
    &.blue {
        @include fwc-clear-button($cafm-dark-blue)
    }
}
.fwc-button {
    &.blue {
        @include fwc-button($cafm-dark-blue, $cafm-blue);
    }

    &.aqua {
        @include fwc-button($cafm-blue, $cafm-blue-tint);
        color: $cafm-dark-brown;
        text-shadow: none;
    }

    &.green {
        @include fwc-button($cafm-green, $cafm-green-shade);
    }

    &.red {
        @include fwc-button($cafm-red, $cafm-dark-red);
    }

    &.brown {
        @include fwc-button($cafm-dark-brown, $cafm-light-brown);
    }
}

.anchor-button {
    background: none !important;
    border: none;
    padding: 0 !important;
    /*optional*/
    font-family: $base-font-family; /*input has OS specific font-family*/
    color: $cafm-dark-blue;
    cursor: pointer;

    &.table-button {
        /*y*/
    }
}

.responsive-buttons {
    @media #{$below768} {
        &.one {
            width: 100%;
        }
        &.two {
            :first-child {
                float:left;
            }
            :nth-child(2) {
                float: right;
            }
            .fwc-button {
                width: 49%;
                display: inline-block;
            }
        }
    }
}
