﻿@import "../base/colors";
@import "../helpers/mixins";
@import "../helpers/mediaqueries";

.card {
    border: 1px solid $cafm-dark-blue;
    border-radius: 0px;
    @include standard-box-shadow();

    &.feature {
        border: none;
        box-shadow: none;

        img {
            @include standard-box-shadow();
        }

        .card-img-top {
            border: none;
            padding: 0;
        }
    }

    .card-img-top {
        border-bottom: 1px solid $cafm-dark-blue;
        padding: .3rem;
        max-width: 100%;
        flex-shrink: 0;
    }

    .card-header {
        border-bottom: 1px solid $cafm-dark-blue;
        background-color: $cafm-dark-blue;

        h2, h3 {
            margin-top: 1rem;
            color: $cafm-white;

            @media #{$below768} {
                margin: .2rem 0;
            }
        }

        &.profile {
            background-color: $cafm-light-beige;
            color: $cafm-dark-brown;

            h2 {
                color: $cafm-dark-brown;
            }

            @media #{$below768} {
                text-align: center;
            }
        }

        &.body-collapse {
            border-bottom: none;
        }

        .card-header-buttons {
            text-align: right;

            @media #{$below768} {
                margin-top: .3rem;
                text-align: left;
            }
        }
    }

    &.dashboard {
        h2 {
            margin: 0;
            font-size: 1rem;
        }

        .card-header {
            padding: .5rem;
        }

        .card-body {
            padding: .5rem;
            font-size: .9rem;
            background-color: $cafm-light-beige;

            span .blue {
                color: $cafm-dark-blue;
            }
        }
    }

    &.white-card {
        .card-header {
            background-color: $cafm-white;



            h2, h3 {
                color: $cafm-dark-blue;
            }

            &.brown {
                h2, h3 {
                    color: $cafm-dark-brown;
                }
            }
        }
        .card-body {
            &.achievement-list {
                span, a {
                    list-style:none;
                    font-size: .9rem;
                }
            }
        }
    }

    &.beige-card {
        .card-header {
            background-color: $cafm-light-beige;

            h2, h3 {
                color: $cafm-dark-brown;
            }
        }
    }

    &.info-card {
        .card-header, .card-body {
            background-color: $cafm-light-beige;
            font-size: 1rem;
        }

        p, span, ul, li, h1, h2, h3, h4, h5 {
            color: $cafm-dark-brown;
        }
    }

    .card-header:first-child {
        border-radius: 0;
    }

    .card-footer {
        background-color: $cafm-white;
        border-top: 1px solid $cafm-dark-blue;

        &.body-collapse {
            border-top: none;
        }
    }
}

@media #{$below768} {
    .card-header, .card-body, .card-footer {
        padding: .75rem;
    }
}
