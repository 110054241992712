﻿// _footer.scss
@import "../base/colors";
@import "../base/typography";
@import "../helpers/mixins";

#fwc-footer {
    background-color: $cafm-dark-blue;
    color: $cafm-white;
    margin-top: 60px;
    padding: 38px 0;
    border-top: 6px solid $cafm-dark-blue-tint;
    border-bottom: 6px solid $cafm-dark-blue-tint;
    font-family: 'Raleway', sans-serif;

    a {
        color: $cafm-white;
        text-decoration: underline;

        &:hover, &:focus {
            text-decoration: underline;
            color: white;
        }
    }

    .footer-boxed {
        color: $cafm-white;
        background-color: $cafm-dark-blue-shade;
        padding: 28px;

        @media #{$below768} {
            margin-bottom: 30px;
        }
        & a {
            color: $cafm-white;
        }

        .boxed-title {
            display: block;

            &.social {
                margin: 30px 0 10px;
            }

            &.subscribe {
                margin-top: 15px;

                .svg-inline--fa {
                    margin-left: 10px;
                }
            }
        }
    }

    h3.footer-link-section-title {
        font-size: 21px;
        font-weight: 600;
        color: $cafm-white;
        margin: 0 0 15px;
        padding-bottom: 8px;
        border-bottom: 1px solid $cafm-dark-blue-shade;
    }

    ul.footer-links {
        margin: 0 0 30px 0;
        padding-left: 0;

        li {
            padding: 2px;
            list-style-type: none;

            a {
                font-size: 16px;
                font-weight: 400;
            }
        }
    }

    .copyright {
        font-size: 14px;
        text-align: center;
    }

    .footer-social {
        @include flex;
        flex-wrap: wrap;

        a.social-link {
            position: relative;
            display: block;
            width: 38px;
            height: 38px;
            margin: 5px;
            color: white;
            background-color: $cafm-dark-blue;
            @include transition;

            &:hover {
                background-color: $cafm-blue !important;
            }

            .svg-inline--fa {
                margin: auto;
                left: 0;
                right: 0;
                @include vertical-align(absolute);
            }

            /*&.fb {
                background-color: #3a5a99;
            }

            &.gp {
                background-color: #dc4b3e;
            }

            &.insta {
                background-color: #517ea3;
            }

            &.pin {
                background-color: #bd081c;
            }

            &.sc {
                background-color: #ffea00;
            }

            &.tw {
                background-color: #55acee;
            }

            &.yt {
                background-color: #e52d27;
            }

            &.fr {
                background-color: #FC329B;
            }

            &.vim {
                background-color: #4EBBFF;
            }

            &.li {
                background-color: #0077B5;
            }

            &.ps {
                background-color: #3aa4c6;
            }*/
        }
    }

    .regulatory-statement {
        p {
            text-align: center;
            margin-bottom: 0px;
            padding: 0 10px;

            @media #{$between768and992} {
                padding: 0 125px;
            }
        }
    }
}

#google_translate_element {
    position: relative;

    a {
        pointer-events: none;
        color: $cafm-dark-blue !important;

        &:focus, &:hover {
            color: $cafm-dark-blue !important;
        }
    }

    .goog-te-gadget {
        display: inline-block;
    }

    .goog-te-gadget-simple {
        width: 100%;
        height: 35px;
        border: 0;
        padding-top: 7px;
        @include standard-box-shadow;

        img.goog-te-gadget-icon {
            margin-left: 10px;
        }

        a.goog-te-menu-value {
            font-size: .9rem;
            display: block;
            box-sizing: border-box;
            padding-left: 48px;
            margin: 2px 0;
            width: 100%;
            color: $cafm-dark-blue;

            span[aria-hidden="true"] {
                float: right;
                margin-right: 20px;
            }

            span[style^="border-left"] {
                position: absolute;
                right: 50px;
            }
        }
    }
}