﻿/* _colors.scss -- Color Variables */

$cafm-black: #000000;
$cafm-blue: #72C5D5;
$cafm-blue-tint: #9cd6e1; 
$cafm-light-blue: #C9EFF8;
$cafm-light-blue-tint: #ecf9fd;
$cafm-dark-blue: #38697e;
$cafm-dark-blue-shade: #205267; /*#274958;*/
$cafm-dark-blue-tint: #7396a4;
$cafm-dark-brown: #52473F;
$cafm-light-brown: #82786E;
$cafm-mid-beige: #E1D2CB;
$cafm-light-beige: #F4EBE6;
$cafm-ultralight-beige: #f9f5f2;
$cafm-red: #C86A54;
$cafm-green: #3C905E;
$cafm-green-shade: #217543;
$cafm-dark-red: #A3442D;
$cafm-white: #ffffff;
//$cafm-gray: #656462;
$cafm-light-gray: #AAA9A7;
//$cafm-teal: #83D4CB;
//$cafm-green: #647771;
//$cafm-dark-beige: #CCBEB5;
