﻿/* _stacked-alert-banner.scss -- */
@import "../base/colors";
@import "../base/typography";
@import "../helpers/mixins";

.alert-banner {
    background-color: $cafm-light-blue;
    padding: 30px 0;
    position: relative;
    @include shaded-strips($color:$cafm-dark-blue-tint, $height:3px);
    /*&:not(.partners) {
        @include link-icons;
    }*/
    &.white {
        background-color: #ffffff;
    }

    &.small {
        padding: 8px;
    }

    .alert-icon {
        margin-bottom: 30px;

        .svg-inline--fa {
            font-size: 120px;
            //color: $fwc-not-quite-black;
            opacity: .25;
            text-align: center;
            width: 100%;

            @media #{$below992} {
                font-size: 100px;
            }

            @media #{$below768} {
                font-size: 66px;
            }
        }
    }

    .alert-text {
        font-family: 'Karma', sans-serif;
        font-weight: 300;

        h2 {
            font-family: 'Karma', sans-serif;
            font-weight: 700;
            text-transform: uppercase;

            a {
                color: $cafm-dark-brown;
            }
        }
    }

    &.home {
        //background-color: $cafm-light-blue-tint;
        //@include shaded-strips($color:$cafm-dark-blue-tint, $height:3px);
        background: rgb(32,82,103);
        background: linear-gradient(0deg, $cafm-blue 0%, $cafm-light-blue-tint 100%);
        position: relative;
        border-top: none;

        .brown {
            color: $cafm-dark-brown;
        }

        .blue {
            color: $cafm-dark-blue;
        }
    }
}
