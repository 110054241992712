﻿/* _typography.scss -- */
@import "../helpers/mediaqueries";
@import "../helpers/mixins";
@import "../base/colors";

// Font weights
/*
thin: 100;
extra-light: 200;
light: 300;
regular: 400;
medium: 500;
semi-bold: 600;
bold: 700;
extra-bold: 800;
black: 900;
*/

// Base Font
$base-font-family: 'Raleway', sans-serif;
$base-font-weight: 400;
$base-font-size: 20px;
$base-line-height: 1.5;

// Headings
h1, h2, h3, h4, h5, h6 {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    line-height: 1.1;
    margin: 0;
    color: $cafm-dark-brown;

    a {
        color: $cafm-dark-blue;
    }
}

h1 {
    font-size: 36px;
    font-weight: 500;
}

h2 {
    font-size: 23px;
    margin-bottom: 1rem;
}

h3 {
    font-size: 21px;
    margin-bottom: 1rem;
}

h4 {
    font-size: 21px;
    font-weight: 600;
    margin-bottom: .8rem;
}

h5 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: .7rem;
}

h6 {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: .7rem;
}

p {
    margin: 1rem 0 1.2rem;
}

h1.page-title {
    font-size: 30px;
    margin: 1.5rem 0;
    border-bottom: 1px solid $cafm-dark-blue-tint;
    border-top: 1px solid $cafm-dark-blue-tint;
    padding: 12px 0;
    background-image: url('/Graphics/ui/title-swoosh.png');
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 40% 100%;

    @media #{$below768} {
        font-size:24px;
        background-image: none;
        height: auto;
        margin: 1rem 0;
        padding: 8px 0;
    }
}

h2.section-title, h3.section-title, h4.section-title {
    padding-bottom: 8px;
    border-bottom: 1px solid $cafm-dark-blue;
    //margin-bottom: 30px;
    margin-bottom: 1.25rem;
}

ul, ol {
    margin: 15px 0 15px 0;
    list-style-position: inside;
    padding-left: 30px;

    li {
        padding: 8px;
    }

    ul, ol {
        margin: 0;
    }

    &.minimal {
        margin: 10px 0 10px 0;
        padding-left: 10px;

        li {
            padding: 0 5px;
        }
    }
}

ul {
    > li {
        list-style-type: disc;
    }

    ul li {
        list-style-type: circle;
    }

    ul ul li {
        list-style-type: disc;
    }

    ul ul ul li {
        list-style-type: circle;
    }

    ul ul ul ul li {
        list-style-type: disc;
    }

    ol li {
        list-style-type: decimal;
    }

    ol ol li {
        list-style-type: upper-alpha;
    }

    ol ol ol li {
        list-style-type: decimal;
    }

    ol ol ol ol li {
        list-style-type: lower-alpha;
    }

    ol ol ol ol ol li {
        list-style-type: lower-roman;
    }
}

ol {
    > li {
        list-style-type: decimal;
    }

    ol li {
        list-style-type: upper-alpha;
    }

    ol ol li {
        list-style-type: decimal;
    }

    ol ol ol li {
        list-style-type: lower-alpha;
    }

    ol ol ol ol li {
        list-style-type: lower-roman;
    }

    ul li {
        list-style-type: disc;
    }

    ul ul li {
        list-style-type: circle;
    }

    ul ul ul li {
        list-style-type: disc;
    }

    ul ul ul ul li {
        list-style-type: circle;
    }
}

.list-style-none {
    list-style: none !important;
}

blockquote {
    position: relative;
    padding: 30px;
    border-radius: 8px;
    background-color: #d9d9d9;
    margin-bottom: 30px;
    color: #6a6a6a;
    font-family: 'Karma', serif;
    line-height: 1.5;
    font-size: 1.4rem;
    text-shadow: 1px 1px 1px rgba(255,255,255,0.6);

    p {
        color: #6a6a6a;
        font-family: 'Karma', serif;
        line-height: 1.5;
        font-size: 1.4rem;
        text-shadow: 1px 1px 1px rgba(255,255,255,0.6);
    }

    &:after {
        position: absolute;
        content: "";
        display: block;
        bottom: -22px;
        left: 24px;
        width: 0;
        height: 0;
        border-top: 40px solid #d9d9d9;
        border-right: 40px solid transparent;
        border-left: 40px solid transparent;
    }
}

cite {
    font-weight: 600;
}

em {
    font-style: italic;
}

strong {
    font-weight: 600;
}

.rte {
    @include link-icons;
}

.rte-img {
    display: inline-block;
    max-width: 100%;
    margin: 0 0 1.6rem;

    img {
        width: 100%;
    }

    &.left {
        margin: .5rem 3rem 1.6rem 0;
        max-width: 360px !important;
        width: 100%;
        float: left;
    }

    &.right {
        margin: .5rem 0 1.6rem 3rem;
        max-width: 360px !important;
        width: 100%;
        float: right;
    }

    &.left, &.right {
        @media #{$below768} {
            max-width: 100%;
        }
    }

    &.left, &.right {
        @media #{$below590} {
            max-width: 100%;
            float: none !important;
            margin: inherit auto;
        }
    }
}
@media #{$below768} {
    
    // Headings
    h1, h2, h3, h4, h5, h6 {
        font-family: 'Raleway', sans-serif;
        font-weight: 700;
        line-height: 1.1;
        margin: 0;
        color: $cafm-dark-brown;

        a {
            color: $cafm-dark-brown;
        }
    }

    h1 {
        font-size: 30px;
        font-weight: 500;
    }

    h2 {
        font-size: 20px;
        margin-bottom: .8rem;
    }

    h3 {
        font-size: 18px;
        margin-bottom: .8rem;
    }

    h4 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: .7rem;
    }

    h5 {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: .6rem;
    }

    h6 {
        font-size: 15px;
        font-weight: 300;
        margin-bottom: .6rem;
    }

    p {
        margin: 1rem 0 1rem;
    }

    h2.section-title, h3.section-title, h4.section-title {
        padding-bottom: 5px;
        border-bottom: 1px solid $cafm-dark-blue;
        margin-bottom: 15px;
    }
}

small {
    &.brown {
        color:$cafm-dark-brown;
    }
}

.text-blue {
    color: $cafm-dark-blue;
}