﻿// _mixins.scss --
@import "../base/colors";
@import "../helpers/mediaqueries";

@mixin transition($secs: 0.1s, $ease: ease-in) {
    -webkit-transition: all $secs $ease;
    -moz-transition: all $secs $ease;
    -o-transition: all $secs $ease;
}

@mixin flex() {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin standard-box-shadow($size: 1px) {
    -webkit-box-shadow: 1px 1px $size 0 rgba(0,0,0,0.18);
    -moz-box-shadow: 1px 1px $size 0 rgba(0,0,0,0.18);
    box-shadow: 1px 1px $size 0 rgba(0,0,0,0.18);
}

@mixin shaded-strips($color: rgba(78,78,78,.35), $height: 2px, $z: 1) {
    &:before, &:after {
        content: "";
        display: block;
        position: absolute;
        height: $height;
        width: 100%;
        background-color: $color;
        z-index: $z;
    }

    &:before {
        top: 0;
    }

    &:after {
        bottom: 0;
    }
}

@mixin caption($position: absolute, $color: rgba(57,58,59,.87)) {
    position: $position;
    bottom: 0;
    width: 100%;
    padding: 12px 10px;
    text-align: center;
    color: white;
    background-color: $color;
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    font-weight: 400;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    @include transition;

    @media #{$below1200} {
        font-size: calc(10.8px + 4 * ((100vw - 320px) / 800));
        padding: 6px 10px;
    }

    @media #{$below576} {
        font-size: 16px;
    }
}

@mixin word-break() {
    word-wrap: break-word;
    overflow-wrap: break-word;
    /*word-break: break-word;*/
    -ms-word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

    @media #{$below768} {
        word-break: break-all;
    }
}

// FILTERS
// grayscale      ex: filter: grayscale(100%);
// sepia          ex: filter: sepia(100%);
// saturate       ex: filter: saturate(0%);
// hue-rotate     ex: filter: hue-rotate(45deg);
// invert         ex: filter: invert(100%);
// brightness     ex: filter: brightness(15%);
// contrast       ex: filter: contrast(200%);
// blur           ex: filter: blur(2px);

@mixin filter($filter-type,$filter-amount) {
    -webkit-filter: $filter-type+unquote('(#{$filter-amount})');
    -moz-filter: $filter-type+unquote('(#{$filter-amount})');
    -ms-filter: $filter-type+unquote('(#{$filter-amount})');
    -o-filter: $filter-type+unquote('(#{$filter-amount})');
    filter: $filter-type+unquote('(#{$filter-amount})');
}

@mixin angled-border() {
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;
    background-color: $cafm-dark-brown;

    &:before, &:after {
        display: block;
        position: absolute;
        content: "";
        width: 19px;
        height: 19px;
        background-image: url(/images/corner-tan.png);
    }

    &.top {
        top: 0;

        &:before {
            top: 0;
            left: 0;
            transform: rotate(180deg);
        }

        &:after {
            top: 0;
            right: 0;
            transform: rotate(-90deg);
        }
    }

    &.bottom {
        bottom: 0;

        &:before {
            bottom: 0;
            left: 0;
            transform: rotate(90deg);
        }

        &:after {
            bottom: 0;
            right: 0;
        }
    }
}

@mixin standard-tag($color: $cafm-blue) {
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    height: 34px;
    font-size: 14px;
    font-weight: 700;
    padding: 5px 10px 3px 28px;
    margin-right: 5px;
    border-width: 3px;
    border-radius: 10px;
    border-style: solid;
    background-color: white;
    position: relative;
    overflow: hidden;
    line-height: 1.4;
    white-space: nowrap;
    border-color: $color;
    color: $color;

    &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0px;
        left: 0;
        height: 100%;
        width: 20px;
        background-color: $color;
        @include transition;
    }

    &.active, &:focus, &:hover {
        color: $fwc-blue;

        &:after {
            background-color: $fwc-blue;
        }
    }

    a {
        color: $color;
    }
}

@mixin list-override($margin: 0) {
    margin: $margin;
    list-style-position: unset;
    padding: 0;

    li {
        list-style-type: none;
        padding: 0;
    }
}

@mixin link-icons() {
    // ADD ICONS TO TEXT LINK TYPES
    a:not(.fwc-button), button:not(.fwc-button), input:not(.fwc-button) {
        background-repeat: no-repeat;

        &[href$=".pdf"] {
            padding-left: 15px;
            background-image: url('/Graphics/ui/pdf.png');
            background-position: left 50%;
        }

        &[href$=".png"], &[href$=".jpg"], &[href$=".svg"], &[href$=".jpeg"], &[href$=".gif"] {
            padding-left: 15px;
            background-image: url('/Graphics/ui/pic.png');
            background-position: left 50%;
        }

        &[href$=".doc"], &[href$=".docx"], &[href$=".xls"], &[href$=".xlsx"], &[href$=".txt"] {
            padding-left: 15px;
            background-image: url('/Graphics/ui/doc.png');
            background-position: left 50%;
        }

        &.external {
            padding-left: 15px;
            background-image: url('/Graphics/ui/external.png');
            background-position: left 50%;
        }
    }

    &.teal, &.green, &.red, .teal, .green, .red, .blue {
        &:not(.card-layout) {
            background-repeat: no-repeat;
            // SWITCH ICON COLOR TO WHITE ON COLORED BG
            a:not(.fwc-button), button:not(.fwc-button), input:not(.fwc-button) {
                &[href$=".pdf"] {
                    background-image: url('/Graphics/ui/pdf-white.png');
                }

                &[href$=".png"], &[href$=".jpg"], &[href$=".svg"], &[href$=".jpeg"], &[href$=".gif"] {
                    background-image: url('/Graphics/ui/pic-white.png');
                }

                &[href$=".doc"], &[href$=".docx"], &[href$=".xls"], &[href$=".xlsx"], &[href$=".txt"] {
                    background-image: url('/Graphics/ui/doc-white.png');
                }

                &.external {
                    background-image: url('/Graphics/ui/external-white.png');
                }
            }

            p {
                font-weight: 500;
            }
        }
    }
}

/*@mixin colored-row-green() {
    @include shaded-strips($color:$fwc-green-shade, $height:3px);
    background-color: $fwc-green;
    position: relative;

    h2, h3, h4, h5, p {
        color: white;

        a {
            color: white;
        }
    }

    a {
        color: white;
    }
}

@mixin colored-row-teal() {
    @include shaded-strips($color:$fwc-teal-shade, $height:3px);
    background-color: $fwc-teal;
    position: relative;

    h2, h3, h4, h5, p {
        color: white;

        a {
            color: white;
        }
    }

    a {
        color: white;
    }
}

@mixin colored-row-brown() {
    @include shaded-strips($color:$fwc-dark-tan, $height:3px);
    background-color: $fwc-medium-tan;
    position: relative;
}

@mixin colored-row-white() {
    @include shaded-strips($color:$fwc-medium-tan, $height:3px);
    background-color: white;
    position: relative;
}*/
