﻿// validation.scss

@import "../base/colors";

.success {
    color: $cafm-green;
    font-weight:500;
}
.required {
    color: $cafm-red;
    font-weight: 800;
}
.user-alert,
.error,
.field-validation-error {
    color: $cafm-red;
    font-weight: 500;
    ul li {
        padding: 0px 8px;
        list-style-type: square;
    }
}

.validation-summary-valid {
    display:none;
}
.date-validation-error {
    input[type=text] {
        border: 2px solid $cafm-red !important;
    }
}
.input-validation-error {
    border: 2px solid $cafm-red !important; 
}
.green {
    color: $cafm-green;
}
