﻿@import "../base/colors";
@import "../helpers/mixins";

.datepicker {
    padding: 0 0;
    border: 1px solid $cafm-dark-blue;
    border-radius: 0px;
    @include standard-box-shadow();

    table {
        th, td {
            padding: 8px;
            text-align: center
        }

        &:hover {
            cursor: pointer;
        }
    }

    .datepicker-days {

        .old, .new {
            background-color: #eaeaea;
        }

        .disabled {
            color: #ccc;
        }

        td:not(.disabled):hover {
            background-color: $cafm-dark-blue-shade;
            color: $cafm-white;
        }
    }
}

.fa-calendar {
    position:absolute;
    top:48px;
    right:25px;
    color:$cafm-dark-blue;
}
