﻿@import "../base/colors";

.nav-tabs {
    border-bottom: 1px solid $cafm-dark-brown;

    li {
        list-style: none;

        a {
            border: 1px solid $cafm-dark-brown;
            border-bottom: none;
        }
    }

    .nav-link.active {
        background-color: $cafm-light-beige;
        color: $cafm-dark-brown;
        font-weight: bold;
        border-top: 1px solid $cafm-dark-brown;
        border-right: 1px solid $cafm-dark-brown;
        border-left: 1px solid $cafm-dark-brown;
    }
}
