﻿/* _stacked-video.scss -- */
@import "../base/colors";
@import "../base/typography";
@import "../helpers/mixins";

.carousel-content {
    position: relative;
    height: 100%;
    padding: 10px 10px 40px;
    text-align: center;
    background-color: $cafm-ultralight-beige;

    h1, h2 {
        font-size: 26px;
        text-align: center;
        font-weight: 600;
        color: white;
        margin: 0;
        padding: 5px 0;
        max-height: 80px;
        overflow: hidden;
        color: $cafm-dark-blue-shade;

        @media #{$between768and992} {
            font-size: 28px;
        }

        @media #{$below768} {
            max-height: initial;
        }
    }

    p {
        margin: 0 0 5px;
        max-height: 119px;
        overflow: hidden;
        font-weight: 500;
        font-size: 18px;

        @media #{$below768} {
            max-height: initial;
        }

        @media #{$below480} {
            font-size: 14px;
        }
    }

    a.fwc-button {
        max-height: 80px;
        overflow: hidden;

        @media #{$between768and1200} {
            font-size: 17px;
            padding: 12px 40px;
        }
    }

    .vert-pos {
        @media #{$below768} {
            width: 100%;
            @include vertical-align(absolute);
        }
    }

    @media #{$below768} {
        padding: 10px;
    }

    @include link-icons();
}

.video-with-description {
    .video-content {
        @extend .carousel-content;
        z-index: 2;
        padding: 10px 20px;

        h2 {
            max-height: 100px;
        }

        p {
            max-height: 215px;
        }

        @media #{$between991and1200} {
            font-size: 1rem;
            padding: 1px 20px;

            h2 {
                font-size: 24px;
                max-height: 90px;
            }

            p {
                max-height: 190px;
            }
        }
    }

    .video-item {
        position: relative;

        .video-placeholder {
            padding-top: 56.25%;
            display: block;
            position: relative;
            z-index: 1;
            opacity: 1;
            cursor: pointer;
            @include transition;

            img {
                width: 100%;
                max-width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
            }

            .video-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,.5);
                background-image: url(/Graphics/ui/next.png);
                background-repeat: no-repeat;
                background-position: center center;
                z-index: 1;
            }
        }

        .video-container {
            padding-top: 56.25%;
            display: block;
            position: absolute;
            z-index: 0;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            iframe {
                position: absolute;
                left: 0;
                top: 0;
                width: 100% !important;
                height: 100% !important;
            }

            &.active {
                opacity: 1;
            }
        }

        &.active {
            .video-placeholder {
                opacity: 0;
                z-index: 0;
            }

            .video-container {
                z-index: 1;
            }
        }
    }
}
