﻿/* _header.scss -- */
@import "../base/colors";
@import "../base/typography";
@import "../helpers/mixins";

#fwc-header {
    background-color: $cafm-light-beige;
    height: 125px;
    border-top: 6px solid $cafm-dark-blue-tint;
    @include standard-box-shadow($size: 3px);

    .container {
        position: relative;
        height: 100%;
        padding: 0;

        @media #{$below992} {
            padding: 0 15px;
        }
    }

    @media #{$below768} {
        height: 115px;
    }

    @media #{$below576} {
        height: 115px;
    }

    #btn-submit-catches {
        position: absolute;
        top: 30px;
        right: 0px;

        @media #{$below992} {
            top: 65px;
            right: 15px;
        }
        
    }

    .site-logo {
        width: 380px;
        height: 111px;
        display: block;
        position: relative;
        @include vertical-align;

        @media #{$below768} {
            height: 110px;
        }

        img {
            max-width: 115px;

            @media #{$below576} {
                max-width: 100px;
            }

            @media #{$below480} {
                max-width: 90px;
            }

            @media #{$below375} {
                display:none;
            }
        }

        .logo-title {
            display: block;
            color: $cafm-dark-brown;
            font-family: 'Myriad Pro', Arial, sans-serif;
            font-weight: 700;
            position: absolute;
            left: 120px;
            top: 15px;
            font-size: 22px;
            line-height: 1.1;

            span {
                display: block;
                white-space: nowrap;
            }

            @media #{$below768} {
                font-size: 18px;
                top: 15px;
            }

            @media #{$below576} {
                font-size: 16px;
                left: 105px;
                top: 10px;
            }

            @media #{$below480} {
                left: 95px;
            }

            @media #{$below375} {
                left: 0;
            }
        }
    }

    .hamburger {
        display: none;
        position: absolute;
        right: 15px;
        bottom: 55px;
        padding: 0;

        @media #{$below992} {
            display: block;
        }

        @media #{$below768} {
            top: 10px;
        }

        @media #{$below576} {
            top: 10px;
        }

        .hamburger-box {
            width: 57px;
            height: 42px;
        }

        .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
            width: 100%;
            height: 10px;
            background-color: $cafm-dark-brown;
            border-radius: 0;
            @include standard-box-shadow;
        }

        &.hamburger--slider:not(.is-active) .hamburger-inner:before {
            top: 16px;
        }

        &.hamburger--slider:not(.is-active) .hamburger-inner:after {
            top: 32px;
        }

        &.hamburger--slider.is-active {
            .hamburger-inner {
                top: 11px;
            }
        }
    }

    .mobile-menu-text {
        display: none;

        @media #{$below992} {
            display: block;
            position: absolute;
            text-transform: uppercase;
            font-size: 21px;
            font-weight: 700;
            right: 85px;
            bottom: 80px;
            color: $cafm-dark-brown;
        }

        @media #{$below768} {
            top: 10px;
            font-size: 18px;
        }

        @media #{$below576} {
            display: none;
        }
    }
}
